import $ from 'jquery';
import 'jquery-validation';
import jqForm from 'jquery-form';
import 'jquery.maskedinput/src/jquery.maskedinput'
import 'bootstrap/js/dist/modal'

import './helpers/bs_modal_fix'
import './helpers/valid'
import './helpers/bsModalCenter'

/* libs */
import './lib/modernizr-custom'
import './lib/lightslider.min'

/* plugins */
import './plugins/tooltip'

/* separate */
import './helpers/resizer';
import './separate/global';
import './helpers/object-fit';
import './helpers/toggle-blocks';

/* components */
import './components/common'
import './components/js-header'
import './components/js-map'
import './components/js-select'
import './components/tooltip-thrower'
import './components/common-form'
import './components/login-form'
import './components/register-form'
import './components/remind-form'
import './components/quick-order'
import './components/checkout'
import './components/profile-form'
import './components/ajax-form'
import './components/faq'
import './components/alert-message'
import './components/settings-form'
import './components/product'
import './components/team'
import AlertMessage from "./components/alert-message";


jqForm();

var html = $('html'),
    body = $('body'), ST;


$('.header__menu button').on('click', function () {
    body.toggleClass('nav-active');
});

$(".product__carousel-items").lightSlider({
    item       : 1,
    mode       : 'fade',
    speed      : 500,
    slideMove  : 1,
    slideMargin: 0
});

$(".carousel__items").lightSlider({
    item       : 1,
    mode       : 'fade',
    speed      : 500,
    slideMove  : 1,
    slideMargin: 0
});


$(window).on('load', function () {
});
$(window).on('resize', function () {
});
$(window).on('scroll', function () {
});


// modal close/open
$('.modal__wrapper, .modal-close').on('click', function () {
    $(this).parents('#modalWindow').modal('hide');
});
$('.modal__block').on('click', function (e) {
    e.stopPropagation();
});

$('.modal-open').on('click', function () {
    $('#modalWindow').modal('show');
});


// up btn
$(window).scroll(function () {
    if ($(this).scrollTop() > 680) {
        $('#scroll-top').addClass('visible sticky');
    } else {
        $('#scroll-top').removeClass('visible sticky');
    }
});

$('#scroll-top').click(function () {
    $('html, body').animate({scrollTop: 0}, 1300);
    return false;
});

$(document).ready(function () {

    AlertMessage.__showsDelayedMessages();

    // modal close/open
    $('.modal__wrapper, .modal-close').on('click', function () {
        $(this).parents('#modalWindow').modal('hide');
    });
    $('.modal__block').on('click', function (e) {
        e.stopPropagation();
    });

    $('.modal-open').on('click', function () {
        $('#modalWindow').modal('show');
    });

    // up btn
    $(window).scroll(function () {
        if ($(this).scrollTop() > 680) {
            $('#scroll-top').addClass('visible sticky');
        } else {
            $('#scroll-top').removeClass('visible sticky');
        }
    });

    $('#scroll-top').click(function () {
        $('html, body').animate({scrollTop: 0}, 1300);
        return false;
    });

    // scroll to section
    $('.scroll-to-app').on('click', function (e) {
        e.preventDefault();
        var idSection = $(this).attr('href'),
            section = $(idSection).offset().top;
        let speed = parseInt($(this).data('speed'));
        let top = parseInt($(this).data('top'));
        if (!isNaN(top)) {
            section += top;
        }
        $('body, html').animate({scrollTop: section}, speed > 0 ? speed : 1000);
    });


    $('.revive-dropdown .selected').click(function () {
        const parent = $(this).parent('.cur, .lang');

        $('.revive-dropdown div.lang, .revive-dropdown div.cur').each(function (i, e) {
            if (!$(e).is(parent)) {
                $(e).find('div[class!="selected"]').addClass('hidden')
                $(e).find('.arrow-down-close').removeClass('open');
                $(e).removeClass('show-sel')
            }
        })

        const notSelected = parent.find('div[class!="selected"]');
        if (notSelected.hasClass('hidden')) {
            $(this).find('.arrow-down-close').toggleClass('open');
            notSelected.removeClass('hidden');
            parent.addClass('show-sel')
        } else {
            $(this).find('.arrow-down-close').toggleClass('open');
            notSelected.addClass('hidden');
            parent.removeClass('show-sel');
        }
    });
    $('.cur div[class!="selected"]').on('click', function (e) {
        changeCurrency($(this).data('currency'));
    });
    $('.lang div[class!="selected"]').on('click', function (e) {
        location.href = $(this).data('href');
    });


    function changeCurrency(currency) {
        let lang = ($('html')).attr('lang');
        let message = lang === 'ru'
            ? 'Из-за изменения валюты ваша корзина была очищена'
            : 'Due to currency change your basket has been cleared'
        $.ajax({
            dataType: 'json',
            url     : '/user/changeCurrency',
            method  : 'POST',
            data    : {currency: currency}
        }).done(response => {
            if (response?.data?.cleaned) {
                AlertMessage.saveWarningAfterReload(message);
            }
            if (response?.data?.changed) {
                document.location.reload();
            }
        }).fail(error => {
            console.log(error);
        })
    }

    $(window).scroll(function () {
        if (!isVisible($(".revive-dropdown"))) {
            $('.revive-dropdown div.lang, .revive-dropdown div.cur').each(function (i, e) {
                $(e).find('div[class!="selected"]').addClass('hidden')
                $(e).find('.arrow-down-close').removeClass('open');
                $(e).removeClass('show-sel')
            })
        }
    });

    if(document.location.pathname==='/') {
        let counter=$('#prodCount');
        let current=parseInt(counter.data('current'));
        let count=parseInt(counter.data('count'));
        let dif=count-current;
        let iterations=[];
        let iter3=dif-10;
        iterations[4]=dif;
        iterations[3]=iter3;
        iterations[2]=iter3-parseInt(Math.ceil(dif*0.15));
        iterations[1]=iter3-parseInt(Math.ceil(dif*0.30));
        iterations[0]=iter3-parseInt(Math.ceil(dif*0.5));
        let tickDensity = [30,20,20,30,10];
        let tickSize=[];
        let tickDelay=[];
        tickSize[0]=iterations[0]/tickDensity[0];
        tickDelay[0]=1000/tickDensity[0];
        for (let i=1;i<=4;i++) {
            tickSize[i]=(iterations[i]-iterations[i-1])/tickDensity[i];
            tickDelay[i]=1000/tickDensity[i];
        }
        let iterNum=0;
        let tick=0;
        let counting=current;
        increase();
        function increase() {
            counting+=tickSize[iterNum];
            let show=Math.round(current);
            tick++;
            if(tick>=tickDensity[iterNum]) {
                tick=0;
                iterNum++;
                if(counting>current+iterations[iterNum-1]) counting=current+iterations[iterNum-1];
            }
            counter.text(Math.round(counting).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
            if(iterNum<5) {
                setTimeout(function(){
                    increase()
                }, tickDelay[iterNum]);
            }
        }
        setInterval(function(){
            $.ajax({
                dataType: 'json',
                url     : '/',
                method  : 'POST',
                data    : {action: 'counter'}
            }).done(function(json){
                if(json.data.counter!==undefined) {
                    $('#prodCount').text(json.data.counter.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " "));
                }
                if(json.data.text!==undefined){
                    $('#prodText').text(json.data.text);
                }
            })
            .fail(error => {
                console.log(error);
            })
        },14000);
    }
    $.mask.definitions['9'] = '';
    $.mask.definitions['n']='[0-9]';
});


function isVisible($el) {
    var winTop = $(window).scrollTop();
    var winBottom = winTop + $(window).height();
    var elTop = $el.offset().top;
    var elBottom = elTop + $el.height();
    return ((elBottom <= winBottom) && (elTop >= winTop));
}
