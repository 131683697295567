import $ from 'jquery';

$(function(){
    if( document.location.pathname.substring( 0, 9 ) !== '/product/' ) {
        return;
    }
    let prodView = $('#prod-view');
    let prodViewPos = prodView.offset();
    let prodImages = $('#prod-carousel');
    let prodImagesHeight = prodImages.height();
    let floating = false;
    let win = $(window);
    let winWidth = win.width();
    let footerTop=$('footer').offset().top;

    $(window).on('scroll', function(){
        if( winWidth < 768 ) {
            return;
        }
        let ws = $(this).scrollTop();
        if( ws >= prodViewPos.top ) {
            if( ! floating ) {
                floating = true;
                prodImages.addClass('float').css({width:prodView.width(), left:prodViewPos.left, top: 0 });
            }
        } else {
            if( floating ) {
                floating = false;
                prodImages.removeClass('float').css({width:'auto'});
            }
        }
        if( ( prodImagesHeight + 20 ) >= ( footerTop - ws ) ) {
            prodImages.css({top:( footerTop - ws ) - ( prodImagesHeight + 20 )});
        } else {
            prodImages.css({top: 0});
        }
    }).on('resize',function(){
        winWidth = win.width();
        prodImagesHeight = prodImages.height();
        prodViewPos = prodView.offset();
        if( floating ) {
            prodImages.css({width:prodView.width()});
        }
        if( winWidth < 768 ) {
            if( floating ) {
                floating = false;
                prodImages.removeClass('float').css({width:'auto'});
            }
        }
    });
});