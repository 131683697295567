import $ from 'jquery';
import ReviveAjax from '../services/ajax';

const TIMEOUT = 300;
const lang = $('html').attr('lang');
let url;
if (lang === 'ru' || lang === '') {
    url = '/cabinet/cart/apply';
}
if (lang === 'en') {
    url = '/en/cabinet/cart/apply';
}
const APPLY_URL = url

class CartService {

    constructor() {
        this.applyInProcess = false;
        this.timer = null;
        this.productCounts = {};
        this.stockSizes = {};
        this.diff = {};
        this.jq = $({});
        this.inputs = {}
    }

    /**
     * Takes inputs data
     * Starts listen inputs events
     * @param inputs
     */
    applyInputs(inputs) {
        this.inputs = inputs;
        inputs.each((index) => {
            let input = $(inputs.get(index));

            let productId = input.data('product');
            this.productCounts[productId] = parseInt(input.val());
            this.stockSizes[productId] = parseInt(input.data('stock-size'));
        });
        inputs.change((e) => {
            let input = $(e.target);

            let id = input.data('product');
            let currentCount = parseInt(input.val());
            if (currentCount > this.stockSizes[id]) {
                currentCount = this.stockSizes[id];
                input.val(currentCount);
            }
            let hasChanges = false;

            if (this.productCounts.hasOwnProperty(id)) {
                if (this.productCounts[id] !== currentCount) {
                    this.diff[id] = currentCount - this.productCounts[id];
                    hasChanges = true;
                }
            }
            if (hasChanges) {
                let totalCount = 0;
                for (let id of Object.keys(this.productCounts)) {
                    totalCount += this.productCounts[id];
                }
                this.jq.trigger('inputChange', {
                    input: input,
                    count: currentCount,
                    totalCount: totalCount,
                    stock: this.stockSizes[id],
                });
                this._scheduleApplyBasketDiff();
            }
        });
    }

    on(event, callback) {
        this.jq.on(event, function () {
            let args = Array.from(arguments);
            args.shift();
            callback.apply(this, args);
        });
    }

    /**
     * Starts new timer for save cart diffs in backend and clear last timer
     * aborts when ajax request is in progress
     * @private
     */
    _scheduleApplyBasketDiff() {
        if (this.applyInProcess) {
            return;
        }
        if (null != this.timer) {
            clearTimeout(this.timer);
        }

        this.timer = setTimeout(() => {
            this._processApplyBasketDiff();
        }, TIMEOUT);
    }

    /**
     * Starts apply ajax request
     * @private
     */
    _processApplyBasketDiff() {
        this.applyInProcess = true;

        let ajaxData = {
            cart: Object.assign({}, this.diff),
            uri: document.location.pathname
        };
        let prevProductCounts = Object.assign({}, this.productCounts);

        for (const id in this.diff) {
            if (!this.diff.hasOwnProperty(id)) {
                return;
            }
            this.productCounts[id] += this.diff[id];
            delete this.diff[id];
        }

        // trigger productCounts change
        let totalCount = 0;
        for (let id of Object.keys(this.productCounts)) {
            totalCount += this.productCounts[id];
        }
        this.jq.trigger('countChange', {
            count: totalCount,
        });

        let doRetry = false;
        let ajax = new ReviveAjax({
            data: ajaxData,
            dataType: 'json',
            url: APPLY_URL,
            method: 'POST',
        });

        ajax.process()
            .then(data => {
                this.jq.trigger('applySuccess', data);
                if (!$.isEmptyObject(this.diff)) {
                    doRetry = true;
                }
            })
            .catch(reason => {
                this.jq.trigger('ajaxError', {ajax: ajax});
                if (!$.isEmptyObject(this.diff)) {
                    doRetry = true;
                }
                let lastDiff = CartService._calcCountsDiff(prevProductCounts, this.productCounts);
                this.productCounts = prevProductCounts;
                if (!$.isEmptyObject(lastDiff)) {
                    for (let key of Object.keys(lastDiff)) {
                        if (this.diff.hasOwnProperty(key)) {
                            this.diff[key] += lastDiff[key];
                        } else {
                            this.diff[key] = lastDiff[key];
                        }

                        if (0 === this.diff[key]) {
                            delete this.diff[key];
                        }
                    }
                }
            })
            .finally(() => {
                this.applyInProcess = false;
                if (doRetry && !$.isEmptyObject(this.diff)) {
                    this._applyDiff();
                    this._scheduleApplyBasketDiff();
                }
            });
    }

    /**
     * Apply diff to inputs
     * @private
     */
    _applyDiff() {
        this.inputs.each((index) => {
            let that = $(this.inputs.get(index));
            let id = that.data('product');
            if (this.diff.hasOwnProperty(id)) {
                that.val(parseInt(that.val()) + parseInt(this.diff[id]));
            }
        });
    }

    /**
     * Calculates different in two productCounts
     * @param from
     * @param to
     * @private
     */
    static _calcCountsDiff(from, to) {
        let result = {};
        for (const id in to) {
            if (!to.hasOwnProperty(id)) {
                return;
            }
            if (id in from) {
                result[id] = to[id] - from[id];
            } else {
                result[id] = to[id];
            }
        }
        return result;
    }
}

const cartService = new CartService();
export default cartService;
