import $ from 'jquery';

class AlertMessage {

    static __showsDelayedMessages() {
        let messages = JSON.parse(localStorage.getItem('alertMessages'));
        if (!messages) {
            return;
        }

        messages.error.forEach((message, i) => {
            setTimeout(() => {
                AlertMessage.showError(message);
            }, i * 1000);
        })
        messages.success.forEach((message, i) => {
            setTimeout(() => {
                AlertMessage.showSuccess(message);
            }, i * 1000);
        })
        messages.warning.forEach((message, i) => {
            setTimeout(() => {
                AlertMessage.showWarning(message);
            }, i * 1000);
        })
        localStorage.removeItem('alertMessages');
    }

    static saveErrorAfterReload(text) {
        let messages = JSON.parse(localStorage.getItem('alertMessages'));
        if (messages?.error) {
            messages.error.push(text);
        } else {
            messages = {error: [text], success: [], warning: []}
        }

        localStorage.setItem('alertMessages', JSON.stringify(messages));
    }

    static saveSuccessAfterReload(text) {
        let messages = JSON.parse(localStorage.getItem('alertMessages'));
        if (messages?.success) {
            messages.success.push(text);
        } else {
            messages = {error: [], success: [text], warning: []}
        }

        localStorage.setItem('alertMessages', JSON.stringify(messages));
    }

    static saveWarningAfterReload(text) {
        let messages = JSON.parse(localStorage.getItem('alertMessages'));
        if (messages?.warning) {
            messages.warning.push(text);
        } else {
            messages = {error: [], success: [], warning: [text]}
        }

        localStorage.setItem('alertMessages', JSON.stringify(messages));
    }

    static showError(text) {
        $('.alert-message').remove();
        let element = $('<div class="alert-message alert-message_error"></div>');
        $(document.body).append(element);
        AlertMessage.__createComponent(element, text);
    }

    static showWarning(text) {
        $('.alert-message').remove();
        let element = $('<div class="alert-message alert-message_warning"></div>');
        $(document.body).append(element);
        AlertMessage.__createComponent(element, text);
    }

    static showSuccess(text) {
        $('.alert-message').remove();
        let element = $('<div class="alert-message alert-message_success"></div>');
        $(document.body).append(element);
        AlertMessage.__createComponent(element, text);

    }

    static hideError() {
        $('.alert-message_error').remove();
    }

    static __createComponent(element, text) {
        let close = $('<span class="alert-message__close">×</span>');
        element.empty();
        element.addClass('alert-message_fixed');
        element.append($('<span class="alert-message__text">' + text + '</span>'));
        element.append(close);
        close.click(() => {
            element.remove();
        });
        setTimeout(() => {
            element.remove();
        }, 15000);
    }

}

let message = $('.alert-message');
let text = message.text();
if (text) {
    AlertMessage.__createComponent(message, message.text());
}
export default AlertMessage;
