import $ from 'jquery';

    let form = $('.remind-form');
    let hashInput = form.find('input[name="captchaHash"]');

    function __updateCaptcha(hash, url) {
        hashInput.val('hash');
        form.find('.captcha-image img').attr('src', url);
    }

    form.on('submit', function (e) {
        e.preventDefault();
        let errorElement = form.find('.form-error');
        errorElement.empty();
        form.validate();
        if (true === form.valid()) {
            form.ajaxSubmit({
                url: '/auth/remindAjax',
                dataType: 'json',
                type: 'post',
                success: function (result) {
                    let data = {};
                    if (result.hasOwnProperty('data')) {
                        data = result.data;
                    }
                    if (data.error) {
                        errorElement.text(data.error);
                        if (null == data.captcha) {
                            alert('ошибка обновления картинки')
                        } else {
                            __updateCaptcha(data.captcha.hash, data.captcha.url);
                        }
                    } else {
                        form.hide();
                        $('.password-sent').show();
                    }
                },
                error: function () {
                    errorElement.text('Ошибка восстановления пароля');
                }
            });
        }
        return false;
    });
    form.find('.refresh').click(function () {
        __updateCaptcha(hashInput, '/captcha/' + hashInput.val() + '/' + Math.random());
    });