import $ from 'jquery';
import cartService from '../services/cartService';
import ReviveAjax from '../services/ajax';
import AlertMessage from '../components/alert-message';

// ticker
$('.ticker-close').on('click', function () {
    (new ReviveAjax({
        dataType: 'json',
        url: '/user/lockNote',
        method: 'POST',
    }))
        .process()
        .then(() => {
            $(this).closest('.ticker-wrapper').remove();
        });
});

cartService.on(
    'countChange',
    data => $('.header__basket a span').text(data.count)
);
