import $ from 'jquery';
import AlertMessage from './alert-message';

let form = $('.login-form');

form.on('submit', function (e) {
    e.preventDefault();
    let referer = form.data('referer');
    let refererLang = referer.match(/^\/?[a-zA-Z]{2}\//i);
    if (refererLang && refererLang.length) {
        refererLang = refererLang[0].replace(/\//g, '');
    }
    const defaultLang = $('html').data('default-lang');
    const lang = $('html').attr('lang');
    const langPrefixUrl = lang !== defaultLang ? `/${lang}` : '';
    form.validate();
    if (true === form.valid()) {
        form.ajaxSubmit({
            url: `${langPrefixUrl}/auth/loginAjax`,
            dataType: 'json',
            type: 'post',
            success: function (result) {
                let data = {};
                if (result.hasOwnProperty('data')) {
                    data = result.data;
                }
                if (data.error) {
                    AlertMessage.showError(data.error);
                } else {
                    if (data?.user?.language) {
                        if (refererLang !== null) {
                            if (refererLang !== data.user.language) {
                                if(data.user.language === defaultLang){
                                    referer = referer.replace(langPrefixUrl, '')
                                }else{
                                    referer = referer.replace(langPrefixUrl, '/'+data.user.language)
                                }
                            }
                        }else{
                            if(data.user.language !== defaultLang){
                                referer = `/${data.user.language}${referer}`
                            }
                        }
                    }
                    location.href = referer;
                }
            },
            error: function () {
                AlertMessage.showError('Ошибка авторизации');
            }
        });
    }
    return false;
});
