import $ from 'jquery';

$.mask.definitions['n'] = '[0-9]';
let phoneInput = $('form .form-phone');
let citySelect = $('form .form-city-select');

function __setPhoneCityMask() {
    let option = citySelect.find("option:selected");
    if (option.length > 0) {
        phoneInput.mask(option.data('mask'));
    }
}

citySelect.change(function () {
    __setPhoneCityMask();
});
__setPhoneCityMask();

$(function(){
    if( document.location.pathname.substring( 0, 9 ) !== '/article/' ) {
		return;
	}
    if( gkey !== undefined ) {
        setTimeout(function () {
            $.ajax({
                url : document.location.href,
                type : 'POST',
                data: {hit:1},
                cache: false
            });
        }, 15000);
    }
});