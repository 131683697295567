import $ from 'jquery';

let form = $('.s-card-payment__form');
let deliveryConditions = $('#check_delivery_payment');
let privacyCheckbox = $('#check_privacy_policy');

$('div.s-checkout__btn__submit').click((e) => {
    e.preventDefault();
    e.stopPropagation();
    form.submit();
    return false;
});

deliveryConditions.change(() => $('input[name="conditions"]')
    .val(+deliveryConditions.is(':checked')));
privacyCheckbox.change(() => $('input[name="privacy"]')
    .val(+privacyCheckbox.is(':checked')));
