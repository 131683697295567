import $ from 'jquery'
import cartService from '../services/cartService'
import AlertMessage from './alert-message'
import TooltipThrower  from './tooltip-thrower'

const INPUTS_SELECTOR = '.cart-input-count input';
let quickOrderElement;
let lastClickPosition;


/**
 *
 * @param productInput
 * @param value
 * @param stockSize
 * @private
 */
function __checkItemStocks(productInput, value, stockSize) {
    let container = productInput.closest('.s-short-order__item-count');
    if (value >= stockSize) {
        container.removeClass('s-short-order__item-count-stock-available');
        container.addClass('s-short-order__item-count-out-of-stock');
        container.find('.s-short-order__item-count-wrap').rvTooltip(
            quickOrderElement.data('out-of-stock-tooltip'),
            {start: lastClickPosition})
    } else {
        container.addClass('s-short-order__item-count-stock-available');
        container.removeClass('s-short-order__item-count-out-of-stock');
        container.find('.s-short-order__item-count-wrap').rvTooltip(null);
    }
}

/**
 * @param main
 * @private
 */
function __initQuickOrder(main) {
    let cartInputs = main.find(INPUTS_SELECTOR);
    cartService.applyInputs(cartInputs);


    quickOrderElement = main.find('.quick-order');


    quickOrderElement.find('.s-short-order__item-count-out-of-stock .s-short-order__item-count-wrap')
        .rvTooltip(quickOrderElement.data('out-of-stock-tooltip'), {start: lastClickPosition});
    quickOrderElement.find('.s-short-order__item-disabled')
        .rvTooltip(quickOrderElement.data('not-available-tooltip'));


    main.find('.minus, .plus').on('click', function (e) {
        lastClickPosition = {x: e.pageX, y: e.pageY};
        $(this).parents(".s-short-order__item-count-wrap").addClass('input-animate');
        setTimeout(RemoveClass, 1500);

        function RemoveClass() {
            $(".s-short-order__item-count-wrap").removeClass("input-animate");
        }
    });
    main.find('.minus').click(function () {
        const input = $(this).parent().find('.form-text');
        let count = parseInt(input.val()) - 1;
        count = count < 0 ? 0 : count;
        input.val(count);
        input.change();
        return false;
    });
    main.find('.plus').click(function () {
        const input = $(this).parent().find('.form-text');
        input.val(parseInt(input.val()) + 1);
        input.change();
        return false;
    });
    main.find('.s-short-order__checkout-clean a').click(() => {
        cartInputs.each(function () {
            $(this).val(0).change();
        });
    });
    main.find('.s-short-order__checkout-issue button.link-button').click(function () {
        let stopMsg = $(this).data('order-stop');
        if(stopMsg) {
            AlertMessage.showError(stopMsg);
        } else {
            location.href = $(this).data('href');
        }
    });

    new TooltipThrower(main);
}

cartService.on('inputChange', (e) => {
    __checkItemStocks(e.input, e.input.count, e.input.stock);
});

cartService.on('ajaxError', (e) => {
    AlertMessage.showError('Ошибка сохранения данных в корзине');
});

cartService.on('applySuccess', (data) => {
    AlertMessage.hideError();
    let dom = $(data.html);
    let inputs = dom.find(INPUTS_SELECTOR);
    let main = $('main');
    main.empty().append(dom);
    cartService.applyInputs(inputs);

    __initQuickOrder(main);
});

__initQuickOrder($('main'));