import $ from 'jquery';

let form = $('.js-form-check');
let button = form.find('.process-login');
button.on('click', function () {
    form.submit();
});
form.find('input').on('keypress', (function (e) {
    let keyCode = parseInt(e.keyCode ? e.keyCode : e.which);
    if (keyCode === 13) {
        button.click();
    }
}));