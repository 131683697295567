import $ from 'jquery';

$.fn.rvTooltip = function (text, options) {
    if (!options) {
        options = {};
    }
    this.each(function () {
        const that = $(this);
        let tooltipElement = that.find('.revive-tooltip');

        function __mouseEvent(event) {
            if (event.target === tooltipElement.get(0)) {
                return;
            }
            __setPosition(event.pageX + 10, event.pageY + 10);
        }

        function __setPosition(x, y) {
            if (document.body.clientWidth < (x + tooltipElement.width() + 50)) {
                x = document.body.clientWidth - tooltipElement.width() - 50;
            }
            if (document.body.clientHeight < (y + tooltipElement.height() + 50)) {
                y = document.body.clientHeight - tooltipElement.height() - 20;
            }
            tooltipElement.css({left: x + 10, top: y + 10});
        }

        if (tooltipElement.length < 1) {
            if (null == text) {
                that.off('mousemove', __mouseEvent);
                that.removeClass('revive-tooltip__wrapper');
            } else {
                that.on('mousemove', __mouseEvent);
                that.addClass('revive-tooltip__wrapper');
            }
            tooltipElement = $('<div class="revive-tooltip">' + text + '</div>');
            that.append(tooltipElement);
            if (options.start) {
                __setPosition(options.start.x + 10, options.start.y + 10);
            }
        } else if (null == text) {
            tooltipElement.remove();
        }
    });
};