import $ from 'jquery';

    // Core number format

    let form = $('.register-form');

    form.on('submit', function (e) {
        e.preventDefault();
        let errorElement = form.find('.form-error');
        errorElement.empty();
        form.validate();
        if (true === form.valid()) {
            form.ajaxSubmit({
                url: '/auth/registerAjax',
                dataType: 'json',
                type: 'post',
                success: function (result) {
                    let data = {};
                    if (result.hasOwnProperty('data')) {
                        data = result.data;
                    }
                    if (data.error) {
                        errorElement.text(data.error);
                    } else {
                        location.href = '/cabinet/';
                    }
                },
                error: function () {
                    errorElement.text('Ошибка регистрации');
                }
            });
        }
        return false;
    });