import $ from 'jquery'

class ReviveAjax {

    constructor(options) {
        /**
         * 10 - ajax request fail
         * 20 - ajax result ot data is empty
         * 30 - found error in result
         * @type {number}
         */
        if (!options.hasOwnProperty('method')) {
            options.method = 'POST';
        }
        if (!options.hasOwnProperty('dataType')) {
            options.dataType = 'json';
        }
        this.errorCode = NaN;
        this.errorMsg = null;
        this.options = options;
    }

    process() {
        return new Promise((resolve, reject) => {
            $.ajax(this.options)
                .done(result => {
                    if (!result || !result.data) {
                        this.errorCode = 20;
                        reject({target: this});
                        return;
                    }
                    let data = result.data;
                    if ('redirect' === result.channel) {
                        location.href = data;
                        return;
                    }
                    if ('error' in data) {
                        this.errorCode = 30;
                        this.errorMsg = data.error;
                        reject({target: this})
                    } else {
                        resolve(data);
                    }
                })
                .fail(() => {
                    this.errorCode = 10;
                    reject({target: this})
                });
        });
    }

}

export default ReviveAjax;
