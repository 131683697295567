import $ from 'jquery';

$('#profile_form').each(function () {
    let form = $(this);
    let items = form.find('.profile_custom-nav__inner-tabs .custom-nav__item');
    items.each(function () {
        let item = $(this);
        item.find('a').click(e => {
            console.log('link click');
            e.preventDefault();
            if (item.hasClass('active')) {
                return;
            }
            items.removeClass('active');
            item.addClass('active');
            if (item.hasClass('company')) {
                form.find('.s-profile-inner__tab-content:not(.company)').css('display', 'none');
                form.find('.s-profile-inner__tab-content.company').css('display', 'flex');
                form.find('input[name="isCompany"]').val('1')
            } else {
                form.find('.s-profile-inner__tab-content.company').css('display', 'none');
                form.find('.s-profile-inner__tab-content:not(.company)').css('display', 'flex');
                form.find('input[name="isCompany"]').val('0')
            }
        });
    });
});