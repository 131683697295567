import $ from 'jquery';
$(function(){
    if( document.location.pathname.substring( 0, 8 ) !== '/artist/' ) {
		return;
	}

	let vBox = new VenoBox({
		selector: '.album',
		fitView: true,
		infinigall: true,
		navSpeed: 200,
		ratio: '16x9',
		spinner: 'circle-fade',
		bgcolor: 'transparent'
	});

	let sForm = $('#sign-form');
	$('#sign-me').click(function(){
		sForm.addClass('show');
	});
	sForm.find('.sign-form-close').click(function(event){
		event.stopPropagation();
		sForm.removeClass('show');
	});

	if( document.location.hash.length > 0 ) {
		if( document.location.hash.substring( 0, 6 ) === '#album' ) {
			// opening album image
			let image = document.querySelector( document.location.hash );
			vBox.open(image);
		} else if( document.location.hash === '#signup' ) {
			sForm.addClass('show');
		}
	}

});