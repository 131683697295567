import $ from 'jquery';
import ReviveAjax from '../services/ajax';

let ajaxForm = $('.ajax-form');
let form = ajaxForm.find('form');
ajaxForm.on('submit', e => {
    e.preventDefault();
    ajaxForm.removeClass('ajax-form_success');
    ajaxForm.removeClass('ajax-form_error');
    let errorElement = ajaxForm.find('.ajax-form__error');
    let url = ajaxForm.data('action');
    let data = form.serializeArray();
    (new ReviveAjax({
        url: url,
        data: data,
    })).process()
        .then(data => {
            ajaxForm.addClass('ajax-form_success');
        })
        .catch(reason => {
            ajaxForm.addClass('ajax-form_error');
            if (reason.target.errorMsg) {
                errorElement.text(reason.target.errorMsg);
            } else {
                errorElement.text("Ошибка отправки формы");
            }

        });
});