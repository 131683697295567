import $ from 'jquery'
import AlertMessage from './alert-message';
import ReviveAjax from "../services/ajax";

let changePasswordForm = $('.s-settings__change-password-form');
changePasswordForm.submit(e => {
    e.preventDefault();
    let newPassword = changePasswordForm.find('input[name="passwordNew"]').val();
    let repeat = changePasswordForm.find('input[name="passwordNewRepeat"]').val();
    if (newPassword !== repeat) {
        AlertMessage.showError("Новый пароль и повтор не совпадают");
    } else {
        let data = changePasswordForm.serializeArray();
        (new ReviveAjax({
            url: '/cabinet/changePassword',
            data: data,
        })).process()
            .then(data => {
                if (data.hasOwnProperty('success')) {
                    AlertMessage.showSuccess(data.success);
                }
            })
            .catch(reason => {
                if (reason.target.errorMsg) {
                    AlertMessage.showError(reason.target.errorMsg);
                } else {
                    AlertMessage.showError("Ошибка смены пароля");
                }
            });

    }
});
