import $ from 'jquery';

class TooltipThrower {

    constructor(space) {
        let thrower = space.find('.tooltip-thrower');

        thrower.each(function () {
            let text = $(this).data('tooltip');
            if (text) {
                $(this).rvTooltip(text);
            }
        });
        thrower.click(function (e) {
            let that = $(this);
            let text = that.data('tooltip');
            if (text) {
                that.rvTooltip(text, {
                    start: {x: e.pageX, y: e.pageY},
                });
            }

            thrower.removeClass('revive-tooltip_show');
            that.addClass('revive-tooltip_show');
            setTimeout(() => {
                that.removeClass('revive-tooltip_show');
            }, 5000);
        });
    }
}

new TooltipThrower($(document));
export default TooltipThrower